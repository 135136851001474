<template>
  <OutsideCard title="REQUEST_ACCOUNT.SUCCES_TITLE">
    <BaseText>
      {{ $t("REQUEST_ACCOUNT.SUCCES_CONTENT") }}
    </BaseText>
  </OutsideCard>
</template>

<script>
import OutsideCard from "@components/Layout/OutsideCard.vue";

export default {
  name: "RequestAccountSuccess",

  components: {
    OutsideCard,
  },
};
</script>
